import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ContentPage from "../../layouts/contentPage"
import Hero from "../../components/Home/Hero"
import HomeOxygenetic from "../../components/Home/homeOxygenetic"
import Brand from "../../components/Home/brand"
import CategoryBox from "../../components/categoryBox"
import ProducstList from "../../components/productsList"

const IndexPage = () => (
  <Layout headerType="transparent" page="home" lang={'EN'}>
    <Seo title="Homepage" lang={'EN'}/>
      <Hero lang={'EN'}/>
      <ProducstList id="produkty">
        <CategoryBox className="energyze" name="Energyze" color="lime" bgtext="WIT.C" url="/en/products/energyze" lang={"EN"}/>
        <CategoryBox className="relift" name="Relift" color="magenta" bgtext="WIT.A" url="/en/products/relift" lang={"EN"}/>
        <CategoryBox className="acid" name="Acid Peel" color="blue" bgtext="AHA" bgtext2="PHA" url="/en/products/acid-peel" lang={"EN"}/>
      </ProducstList>
      <HomeOxygenetic lang={'EN'}/>
      <Brand lang={'EN'}/>
  </Layout>
)

export default IndexPage
